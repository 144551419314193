.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.appliedFilters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
}

.expandingArea {
  display: flex;
  flex-wrap: wrap;
}

.moreFiltersButton {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-weight: 600;
  padding-top: 4px;
  padding-bottom: 4px;
}

.moreFiltersButtonWrapper {
  display: flex;
  cursor: pointer;
  padding-right: 9px;
  padding-bottom: 9px;
}