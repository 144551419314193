.container {
  width: 200px;
  margin-left: 9px;
}

.link {
  color: #000;
  cursor: pointer;
  margin-right: 9px;
  text-decoration: underline;
  transition: color .2s;
}

.link:hover {
  color: #444;
}

@media (hover: none) {
  .link:hover {
    color: #000;
  }
}

.selected {
  cursor: default;
  pointer-events: none;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 600;
}
