.container {
  flex-flow: wrap;
  display: flex;
}

.appliedFilters {
  flex-flow: wrap;
  align-items: baseline;
  display: flex;
}

.expandingArea {
  flex-wrap: wrap;
  display: flex;
}

.moreFiltersButton {
  padding-top: 4px;
  padding-bottom: 4px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 600;
}

.moreFiltersButtonWrapper {
  cursor: pointer;
  padding-bottom: 9px;
  padding-right: 9px;
  display: flex;
}
