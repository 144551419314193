@keyframes shimmerLoadingBackground {
  0% {
    background-position: 50% 0;
  }

  100% {
    background-position: -50% 0;
  }
}

.container {
  flex-direction: column;
  margin-bottom: 9px;
  display: flex;
}

.wrapper {
  align-items: center;
  margin: 18px 0 0;
  display: flex;
}

.header {
  letter-spacing: -.5px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

@supports (-webkit-line-clamp: 2) {
  .header {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.subHeader {
  margin: 0;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

@supports (-webkit-line-clamp: 2) {
  .subHeader {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.withFollowButton {
  margin-right: 9px;
}

.fewerResultsHeader {
  letter-spacing: -.5px;
  border-top: 1px solid #f3f3f3;
  margin-top: 27px;
  padding-top: 18px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.shimmer {
  backface-visibility: hidden;
  background-image: linear-gradient(to right, #ddd 0%, #f3f3f3 50% 51%, #ddd 100%);
  background-size: 400% 400%;
  animation-name: shimmerLoadingBackground;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  transform: translateZ(0);
}
