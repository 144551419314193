.placeholder {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.placeholder.additionalBlur {
  filter: blur(5px);
}

.placeholder > svg {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.placeholder.isNarrow > svg {
  height: 100%;
}
