.billboardBanner {
  justify-content: center;
  margin-bottom: 54px;
  display: flex;
}

.skyScraper {
  justify-content: center;
  display: flex;
}

.skyScraper.hasAd {
  margin: 63px 0 0;
}

.tileContainer {
  flex-direction: column;
  flex-basis: 50%;
  min-width: 0;
  max-width: 50%;
  margin-top: 9px;
  margin-bottom: 27px;
  padding: 0 18px;
  display: flex;
  position: relative;
}

@media (width >= 1025px) {
  .tileContainer {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
}

@media (width <= 768px) {
  .tileContainer {
    flex-basis: 100%;
    max-width: 100%;
    height: 400px;
    margin-bottom: 0;
  }
}

.tileInner {
  text-align: center;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  display: flex;
}

.tilePlacement {
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.tilePlacement div {
  box-sizing: inherit;
  flex-direction: column;
  display: flex;
}

.tilePlacement div > iframe {
  vertical-align: bottom;
  flex-grow: 1;
}
