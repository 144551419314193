.billboardBanner {
  display: flex;
  justify-content: center;
  margin-bottom: 54px;
}

.skyScraper {
  display: flex;
  justify-content: center;
}
.skyScraper.hasAd {
  margin: 63px 0 0;
}

.tileContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  max-width: 50%;
  min-width: 0;
  margin-top: 9px;
  margin-bottom: 27px;
  padding: 0 18px;
}
@media (min-width: 1025px) {
  .tileContainer {
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}
@media (max-width: 768px) {
  .tileContainer {
    margin-bottom: 0;
    flex-basis: 100%;
    height: 400px;
    max-width: 100%;
  }
}

.tileInner {
  height: 100%;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.tilePlacement {
  display: flex;
  height: 100%;
  margin: 0 auto;
  justify-content: center;
  flex-grow: 1;
}
.tilePlacement div {
  box-sizing: inherit;
  display: flex;
  flex-direction: column;
}
.tilePlacement div > iframe {
  flex-grow: 1;
  vertical-align: bottom;
}