.placeholder {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}
.placeholder.additionalBlur {
  filter: blur(5px);
}
.placeholder > svg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
.placeholder.isNarrow > svg {
  height: 100%;
}