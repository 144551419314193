.wrapper {
  text-align: center;
  border: 1px solid #fff;
  flex-flow: column;
  flex: 100%;
  justify-content: center;
  align-items: center;
  margin: 18px;
  padding: 27px 18px 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 14px;
  font-weight: 300;
  display: flex;
  position: relative;
}

.content {
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.dotWrapper {
  cursor: pointer;
  flex-wrap: nowrap;
  display: flex;
  position: absolute;
  bottom: 9px;
}

.dot {
  border-radius: 50%;
  width: 9px;
  height: 9px;
}

.dot:first-child {
  margin-right: 9px;
}

.selected {
  composes: dot;
  background: #c2a661;
}

.unselected {
  composes: dot;
  background: #bbb;
}
