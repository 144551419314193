/*
// FINDING-6391 due to the way the search/browse price facet "TO" text
// is positioned, it overlaps the actual input boxes. the input boxes
// themselves cannot be set to $nav-utils-wrap-z-index otherwise the
// they will go over the scrolled fix nav. The text and input
// boxes cannot be on the same z-index otherwise the input boxes
// cannot receive focus in the middle of the input box
// if z-index is -1, it will hide the "TO" text beneath the facet
*/
.swiperWrapper {
  position: relative;
  width: 100vw;
  left: calc((100vw - 100%) / -2);
  height: 145px;
}

.carouselWrapper {
  margin-bottom: 72px;
}

.item {
  padding: 27px;
}

.center {
  justify-content: flex-start;
}
.center:first-child {
  justify-content: flex-end;
}

.list {
  justify-content: center;
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40vw;
  max-width: 100%;
  min-height: 145px;
}

.itemLink {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  padding: 0 18px;
  max-width: 100%;
}

.itemImage {
  max-height: 145px;
  max-width: 100%;
}

.headerContainer {
  padding: 0 0 36px;
  text-align: center;
}

.title {
  font-family: "Cardinal Classic Short", "Georgia", "serif";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: -0.5px;
  margin: 0 0 9px;
}

.viewMoreLink {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.spinner {
  margin: auto;
}