.button {
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 300;
  color: #000;
  cursor: pointer;
  transition: color 0.2s ease;
  text-decoration: none;
  margin-top: 9px;
  display: flex;
  align-items: center;
}
.button:hover {
  color: #444;
}
@media (hover: none) {
  .button:hover {
    color: #000;
  }
}

.arrow {
  width: 15px;
  margin-left: 9px;
}

.expanded {
  transform: rotate(180deg);
}