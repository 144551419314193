.item {
  position: relative;
}
.item.xSmall {
  margin: 0;
  padding: 0;
}
.item.small {
  margin-bottom: 18px;
}

.favorites {
  z-index: 10;
  position: absolute;
  top: 0;
}
.favorites.xSmall {
  right: -9px;
}
@media (max-width: 768px) {
  .favorites.xSmall {
    top: -18px;
  }
}
.favorites.small {
  right: 0;
}
.favorites .favoritesWrapper {
  margin: 0;
  padding: 9px;
}
.favorites .favoritesIconWrapper {
  margin-top: 0;
}

.hideTile {
  display: none;
}