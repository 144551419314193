.wrapper {
  composes: wrapper from "./SbSharedTradeBenefitPlacement.scss";
  justify-content: center;
}

.spinnerContainer {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}
