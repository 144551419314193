.button {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
  cursor: pointer;
  align-items: center;
  margin-top: 9px;
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-weight: 300;
  text-decoration: none;
  transition: color .2s;
  display: flex;
}

.button:hover {
  color: #444;
}

@media (hover: none) {
  .button:hover {
    color: #000;
  }
}

.arrow {
  width: 15px;
  margin-left: 9px;
}

.expanded {
  transform: rotate(180deg);
}
