.container {
  flex-wrap: wrap;
  display: flex;
}

.container > :nth-child(-n+4) {
  order: -1;
}

@media (width >= 1025px) {
  .container > :nth-child(-n+6) {
    order: -1;
  }
}

.visuallySimilarWrapper {
  position: absolute;
  bottom: 18px;
  right: 13px;
}

.auctionBanner {
  order: -1;
  width: 100%;
  margin: 27px 0;
}
