.wrapper {
  composes: wrapper from "./SbSharedTradeBenefitPlacement.scss";
  justify-content: center;
}

.spinnerContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 100%;
}