.wrapper {
  flex: 1 1 100%;
  margin: 18px;
  border: 1px solid #fff;
  padding: 27px 18px 9px;
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "proxima-nova", "Helvetica Neue", "helvetica", "arial", "sans-serif";
  font-size: 14px;
  font-weight: 300;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.dotWrapper {
  display: flex;
  flex-wrap: nowrap;
  position: absolute;
  bottom: 9px;
  cursor: pointer;
}

.dot {
  border-radius: 50%;
  height: 9px;
  width: 9px;
}
.dot:first-child {
  margin-right: 9px;
}

.selected {
  composes: dot;
  background: #c2a661;
}

.unselected {
  composes: dot;
  background: #bbb;
}