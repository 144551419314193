.swiperWrapper {
  width: 100vw;
  height: 145px;
  position: relative;
  left: calc(-50vw + 50%);
}

.carouselWrapper {
  margin-bottom: 72px;
}

.item {
  padding: 27px;
}

.center {
  justify-content: flex-start;
}

.center:first-child {
  justify-content: flex-end;
}

.list {
  justify-content: center;
}

.placeholder {
  justify-content: center;
  align-items: center;
  width: 40vw;
  max-width: 100%;
  min-height: 145px;
  display: flex;
}

.itemLink {
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 100%;
  padding: 0 18px;
  display: flex;
}

.itemImage {
  max-width: 100%;
  max-height: 145px;
}

.headerContainer {
  text-align: center;
  padding: 0 0 36px;
}

.title {
  letter-spacing: -.5px;
  margin: 0 0 9px;
  font-family: Cardinal Classic Short, Georgia, "serif";
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.4;
}

.viewMoreLink {
  font-family: proxima-nova, Helvetica Neue, helvetica, arial, "sans-serif";
  font-size: 16px;
  font-weight: 300;
}

.spinner {
  margin: auto;
}
